import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import $ from 'jquery';
import 'datatables.net';
import { useNavigate } from 'react-router-dom';
import '../../../node_modules/datatables.net-dt/css/dataTables.dataTables.min.css'; // ../Ensure this path is correct

const BaseURL = "https://server-demo.wodo.digital";

const DataTableComponent = () => {
  const [data, setData] = useState([]);
  const tableRef = useRef(null);
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const response = await axios.get(`${BaseURL}/projects/all`);
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (tableRef.current) {
      const table = $(tableRef.current).DataTable({
        data: data,
        columns: [
          { title: "S.No", data: null, render: (data, type, row, meta) => meta.row + 1 },
          { title: "Title", data: "title" ,
            render: function(data){
              return `<span style=" font-weight: 600;">`+data + `</span>`
            }
          },
          { title: "Country", data: "country" },
          {
            title: "Logo",
            data: "logo",
            render: (data) => `<img src="${data}" alt="Logo" style="width: 40px; height: 40px; object-fit: cover;" />`
          },
          // { title: "Client Excerpt", data: "clientExcerpt" },
          {
            title: "Site URL",
            data: "siteUrl",
            render: (data) => `<a href="${data}" target="_blank" rel="noopener noreferrer" style="color: blue; text-decoration: underline;">${data}</a>`
          },
          { title: "Industry", data: "industry" },
          {
            title: "Status", 
            data: "status",
            render: (data) => {
              // Determine the color based on the status
              let color;
              if (data === 'Publish') {
                color = 'green';
              } else if (data === 'Draft') {
                color = 'yellow';
              } else {
                color = 'gray'; 
              }
              
              return `
                <span style="
                  background-color: ${color};
                  color: white;
                  padding: 2px 8px;
                  border-radius: 3px;
                  font-size: 12px;
                  text-transform: capitalize;
                  display: inline-block;
                ">
                  ${data}
                </span>`;
            }
          }
          
        ],
        paging: true,
        searching: true,
        info: true,
        ordering: true,
        autoWidth: false,
        dom: 'Bfrtip',
        buttons: ['copy', 'csv', 'excel', 'pdf', 'print'],
        rowCallback: (row, data) => {
          console.log('Data:', data); 
          $(row).on('click', () => {
            if (data.slug) {
              navigate(`/projects/${data.slug}`);
            } else {
              console.error('Slug is not defined in data');
            }
          });
        }
        
      });

      return () => {
        // if (table) {
          table.destroy();
        // }
      };
    }

    
  }, [data, navigate]);

  return (
    <div className="p-4 max-w-full mx-auto mt-12">
      <div className="flex justify-between mb-4">
        {/* <input
          type="text"
          placeholder="Search..."
          className="p-2 border-b-2 border-indigo-500"
        /> */}
        <p className='text-2xl font-bold'>All Projects</p>
        <button
          className="px-4 py-2 bg-blue-500 text-white rounded-md"
          onClick={() => window.location.href = "/projects/add-project"}
        >
          Add Project
        </button>
      </div>
      <div className="overflow-x-auto">
        <table id="projectsTable" ref={tableRef} className="display cell-border compact hover order-column row-border stripe w-full text-left">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Title</th>
              <th>Country</th>
              <th>Logo</th>
              {/* <th>Client Excerpt</th> */}
              <th>Site URL</th>
              <th>Industry</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {/* Data rows will be inserted here by DataTables */}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DataTableComponent;
