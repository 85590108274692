import React,{useState,useEffect,useRef,useCallback} from 'react'
import Template from '../../components/Template/Template';
import {Link} from 'react-router-dom'
import Cookies from 'js-cookie';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import $ from 'jquery';
import 'select2/dist/css/select2.min.css';
import 'select2';
import PostAddBlog from './BlogComponents/PostAddBlog';

function AddBlog(props) {
    const [editorData, setEditorData] = useState('');
    const selectCategoriesRef = useRef(null);
    const selectTagsRef = useRef(null);

    function Header(){
        return(
            <div className='text-base mt-12'>
                <div className="flex justify-between">
                    <div className="flex">
                        <Link to='/blogs' className='pt-1 pr-4'>
                            <i className="fas fa-caret-left text-xl text-black"></i>
                        </Link>
                        <h4>New Blog</h4>
                    </div>
                    <div className="flex gap-2">
                        <button className='btn btn-sm'>Save Draft</button>
                        <button className='btn btn-sm'>View</button>
                        <button className='btn btn-sm bg-yellow-500 text-white'>Publish</button>
                    </div>
                </div>
            </div>
        );
    }

    function AddArticle(props){
        return(
            <div className="flex flex-wrap">
                <div className="w-full">
                    <input 
                        type="text" 
                        defaultValue={props.blogtitle} 
                        onChange={props.setBlogtitle} 
                        placeholder='Add Title' 
                        className='bg-transparent border-none text-3xl w-full'
                    />
                    <div className="flex">
                        <div className="w-full">
                            <CKeditorBlog/>
                        </div>
                        <div className="w-1/5">
                            <PostAddBlog blogtitle={props.blogtitle} setBlogtitle={props.setBlogtitle}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function CKeditorBlog(){
        const editorConfiguration = {};
        return(
            <div>
                <CKEditor
                    editor={ClassicEditor}
                    data="<p>Hello from CKEditor 5!</p>"
                    config={editorConfiguration}
                    onReady={(editor) => {
                        console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        console.log({ event, editor, data });
                    }}
                    onBlur={(event, editor) => {
                        console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                        console.log('Focus.', editor);
                    }}
                />
            </div>
        );
    }

    function ModalFeaturedImage(props){
        const [tabFeature, settabFeature] = useState('upload')

        const handleTabFeatureClick = (status) => {
            settabFeature(status);
            Cookies.set('tabStatus', status);
        };

        return(
            <div className="modal fade" id="FeaturedImageModal" data-bs-backdrop="static" tabIndex="9" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-fullscreen">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <h1 className="text-lg">Featured image</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <ul className="nav nav-tabs mt-3">
                                <li className="nav-item">
                                    <span 
                                        className={`cursor-pointer nav-link ${tabFeature==='upload' ? 'active_feature_tab' : ''}`} 
                                        onClick={() => handleTabFeatureClick('upload')}
                                    >
                                        Upload
                                    </span>
                                </li>
                                <li className="nav-item">
                                    <span 
                                        className={`cursor-pointer nav-link ${tabFeature==='media' ? 'active_feature_tab' : ''}`}  
                                        onClick={() => handleTabFeatureClick('media')}
                                    >
                                        Media files
                                    </span>
                                </li>
                            </ul>
                            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 translate-y-1/2">
                                <div className="flex flex-col items-center">
                                    <h6>Drop files to upload</h6>
                                    <h6>or</h6>    
                                    <input type="file" name="" placeholder='Select Files' id="" />
                                    <p>Maximum upload file size: 1 GB.</p>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary">Save changes</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function PageContent(){
        const [BlogTitle, setBlogTitle] = useState('')

        const setBlogTitlehandleInputChange = useCallback((event) => {
            console.log('Input changed:', event.target.value);
            setBlogTitle(event.target.value);
        });

        return(
            <div className="container-fluid">
                <Header/>
                <AddArticle blogtitle={BlogTitle} setBlogtitle={setBlogTitlehandleInputChange}/>
            </div>
        );
    }

    return (
        <>
            <ModalFeaturedImage/>
            <Template currentpage={props.currentpage}>
                <PageContent/>
            </Template>
        </>
    )
}

export default AddBlog;
