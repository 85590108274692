import React, { useState } from 'react';
import AllTabContent from '../blogs/BlogComponents/TabContents/AllTabContents';
import DraftsTabContent from '../blogs/BlogComponents/TabContents/DraftsTabContents';
import PublishedTabContents from '../blogs/BlogComponents/TabContents/PublishedTabContents';

function Blogs() {
  const [activeTab, setActiveTab] = useState("tab1");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="w-full mt-12">
       <div className="flex justify-between mb-4">
        <p className='text-2xl font-bold'>All Blogs</p>
        <button
          className="px-4 py-2 bg-yellow-400 text-white rounded-md"
          onClick={() => window.location.href = "/projects/add-project"}
        >
          Add Blogs
        </button>
      </div>
      <div className="flex border-b border-gray-200">
        <button
          onClick={() => handleTabClick("tab1")}
          className={`py-2 px-4 focus:outline-none ${
            activeTab === "tab1" ? "border-b-2 border-yellow-500 text-yellow-500" : "text-gray-600"
          }`}
        >
          All Blog
        </button>
        <button
          onClick={() => handleTabClick("tab2")}
          className={`py-2 px-4 focus:outline-none ${
            activeTab === "tab2" ? "border-b-2 border-yellow-500 text-yellow-500" : "text-gray-600"
          }`}
        >
          Published
        </button>
        <button
          onClick={() => handleTabClick("tab3")}
          className={`py-2 px-4 focus:outline-none ${
            activeTab === "tab3" ? "border-b-2 border-yellow-500 text-yellow-500" : "text-gray-600"
          }`}
        >
          Draft
        </button>
      </div>

      <div className="w-full h-full">
        {activeTab === "tab1" && <AllTabContent />}
        {activeTab === "tab2" && <PublishedTabContents />}
        {activeTab === "tab3" && <DraftsTabContent />}
      </div>
    </div>
  );
}

export default Blogs;
