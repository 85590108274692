import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { Dropdown } from "./Dropdown";
import { SidebarContext } from "./Sidebar";

export function SidebarItem({ icon, text, id, dropdownItems }) {
  const { expanded } = useContext(SidebarContext);
  const location = useLocation();

  const isActive = () => {
    if (
      location.pathname === `/${id}` ||
      (location.pathname === "/" && id === "dashboard")
    ) {
      return true;
    }
    if (dropdownItems) {
      return dropdownItems.some((item) => location.pathname === item.path);
    }
    return false;
  };

  return (
    <div className="w-full">
      {dropdownItems ? (
        <Dropdown items={dropdownItems}>
          <li
            className={`relative flex items-center py-2 px-3 my-1 font-medium text-sm rounded-md cursor-pointer transition-colors group ${
              isActive()
                ? "bg-gradient-to-tr from-yellow-200 to-black-300 text-black-800"
                : "hover:bg-yellow-50 text-black-600"
            }`}
          >
            {icon}
            <span
              className={`overflow-hidden transition-all ${
                expanded ? "w-52 ml-3" : "w-0"
              }`}
            >
              {text}
            </span>
          </li>
        </Dropdown>
      ) : (
        <Link to={`/${id}`}>
          <li
            className={`relative flex items-center py-2 px-3 my-1 font-medium text-sm rounded-md cursor-pointer transition-colors group ${
              isActive()
                ? "bg-gradient-to-tr from-yellow-200 to-black-300 text-black-800"
                : "hover:bg-yellow-50 text-black-600"
            }`}
          >
            {icon}
            <span
              className={`overflow-hidden transition-all ${
                expanded ? "w-52 ml-3" : "w-0"
              }`}
            >
              {text}
            </span>
          </li>
        </Link>
      )}
    </div>
  );
}
