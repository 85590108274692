import React, { useState, useEffect, useRef, createContext, useContext } from 'react';
import { ChromePicker } from 'react-color';
import axios from 'axios';
import $ from 'jquery';
import 'select2/dist/css/select2.min.css';
import 'select2';

const BaseURL = 'https://server-demo.wodo.digital';

// Create a context for the form data
const FormContext = createContext();

const FormProvider = ({ children }) => {
  const [toggleMDS, setToggleMDS] = useState(false);
  const [projectType, setProjectType] = useState('general');
  const [bannerColor, setBannerColor] = useState('#ffffff');
  const [services, setServices] = useState([]);
  const [newService, setNewService] = useState({ title: '', description: '', icon: '', images: [''] });
  const [results, setResults] = useState([]);
  const [newResult, setNewResult] = useState({ title: '', value: '', unit: '' });
  const [types, setTypes] = useState('other');
  const [industry, setIndustry] = useState([]);
  const [projectTypes, setProjectTypes] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [newType, setNewType] = useState('');
  const [newIndustry, setNewIndustry] = useState('');
  const [status, setStatus] = useState('Draft'); 
  
  const typesRef = useRef(null);
  const industriesRef = useRef(null);

  useEffect(() => {
    const typesSelect = typesRef.current;
    const industriesSelect = industriesRef.current;

    $(typesSelect).select2({ tags: true }).on('change', function () {
      setTypes($(this).val());
    });

    $(industriesSelect).select2({ tags: true }).on('change', function () {
      setIndustry($(this).val());
    });

    const fetchTypesAndIndustries = async () => {
      try {
        const typesResponse = await axios.get(`${BaseURL}/projecttype`);
        setProjectTypes(typesResponse.data.map(type => type.type_name));
        setTypes(typesResponse.data.map(type => type.type_name));

        const industriesResponse = await axios.get(`${BaseURL}/industrytype`);
        setIndustries(industriesResponse.data.map(industry => industry.type_name));
        setIndustry(industriesResponse.data.map(industry => industry.type_name));
      } catch (error) {
        console.error('Error fetching project types and industries:', error);
      }
    };

    fetchTypesAndIndustries();

    return () => {
      $(typesSelect).select2('destroy');
      $(industriesSelect).select2('destroy');
    };
  }, []);

  return (
    <FormContext.Provider
      value={{
        toggleMDS,
        setToggleMDS,
        projectType,
        setProjectType,
        bannerColor,
        setBannerColor,
        services,
        setServices,
        newService,
        setNewService,
        results,
        setResults,
        newResult,
        setNewResult,
        types,
        setTypes,
        industriesRef,
        status,
        setStatus,
        industry,
        setIndustry,
        projectTypes,
        setProjectTypes,
        industries,
        setIndustries,
        newType,
        setNewType,
        newIndustry,
        setNewIndustry,
        typesRef
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

const AddProjectForm = () => {
  const {
    toggleMDS,
    setToggleMDS,
    projectType,
    setProjectType,
    bannerColor,
    setBannerColor,
    services,
    setServices,
    newService,
    setNewService,
    results,
    setResults,
    newResult,
    setNewResult,
    types,
    setTypes,
    industry,
    setIndustry,
    projectTypes,
    setProjectTypes,
    industries,
    setIndustries,
    newType,
    setNewType,
    newIndustry,
    setNewIndustry,
    typesRef,
    industriesRef,
    status,
    setStatus
  } = useContext(FormContext);

  const [formData, setFormData] = useState({
    id: '',
    title: '',
    country: '',
    mds: false,
    logo: '',
    clientDetails: '',
    clientExcerpt: '',
    projectObjective: '',
    projectResult: '',
    websiteExecution: '',
    banner: '',
    bannerColour: '',
    fullSiteImage: '',
    slug: '',
    siteUrl: '',
    execution: '',
    services: [],
    results: [],
    status:'Draft'
  });

  const handleServiceChange = async (index, field, value) => {
    const updatedServices = [...services];
    if (updatedServices[index]) { 
        updatedServices[index][field] = value;
        setServices(updatedServices);
    } else {
        console.error('Invalid index');
    }
};


  const handleNewServiceChange = (field, value) => {
    setNewService({ ...newService, [field]: value });
  };

  async function addService() {
    setServices([...services, { ...newService }]);
    setNewService({ title: '', description: '', icon: '', images: [''] });
  };

  const handleImageChange = (serviceIndex, imageIndex, value) => {
    const updatedServices = [...services];
    updatedServices[serviceIndex].images[imageIndex] = value;
    setServices(updatedServices);
  };

  const handleNewImageChange = (index, value) => {
    const updatedImages = [...newService.images];
    updatedImages[index] = value;
    setNewService({ ...newService, images: updatedImages });
  };

  const addNewImage = () => {
    setNewService({ ...newService, images: [...newService.images, ''] });
  };

  const handleToggleMDS = (value) => {
    setToggleMDS(value);
  };

  const handleProjectType = (type) => {
    setProjectType(type);
  };

  const handleColorChange = (color) => {
    setBannerColor(color.hex);
  };

  const handleResultChange = async (index, field, value) => {
    const updatedResults = [...results];
    if (updatedResults[index]) { // Ensure index is valid
        updatedResults[index][field] = value;
        setResults(updatedResults);
    } else {
        console.error('Invalid index');
    }
};


const handleStatusChange = (event) => {
  setStatus(event.target.value);
};

  const handleNewResultChange = (field, value) => {
    setNewResult(prevState => ({
      ...prevState,
      [field]: value
    }));
  };

  async function addResult()  {
    setResults([...results, { ...newResult }]);
    setNewResult({ title: '', value: '', unit: '' });
  };

  const handleAddType = async () => {
    try {
      const response = await axios.post(`${BaseURL}/projecttype/insert`, { type_name: newType });
      alert(response.data.message);

      if (newType && !projectTypes.includes(newType)) {
        setProjectTypes([...projectTypes, newType]);
        setNewType('');
      }
    } catch (error) {
      console.error('Error adding new type:', error);
      alert('Error adding new type');
    }
  };

  const handleAddIndustry = async () => {
    try {
      const response = await axios.post(`${BaseURL}/industrytype/insert`, { industry_name: newIndustry });
      alert(response.data.message);

      if (newIndustry && !industries.includes(newIndustry)) {
        setIndustries([...industries, newIndustry]);
        setNewIndustry('');
      }
    } catch (error) {
      console.error('Error adding new industry:', error);
      alert('Error adding new industry');
    }
  };
 
  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;
    const val = type === 'checkbox' ? checked : value;
    setFormData((prevState) => ({
      ...prevState,
      [id]: val,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await handleResultChange(); 
    await handleServiceChange();
    await addService();
    await addResult();
    const dataToSend = { ...formData, mds: toggleMDS, bannerColour: bannerColor, services, results, types: types.length ? types : ['Other'], 
      industry: industry.length ? industry : ['Other'], status };

    try {
      const response = await axios.post(`${BaseURL}/projects/insert`, dataToSend);
      alert(response.data.message);
      const jsonData = JSON.stringify(dataToSend, null, 2);
      console.log(jsonData);

      setFormData({
        id: '',
        title: '',
        country: '',
        mds: false,
        logo: '',
        clientDetails: '',
        clientExcerpt: '',
        projectObjective: '',
        projectResult: '',
        websiteExecution: '',
        banner: '',
        bannerColour: '',
        fullSiteImage: '',
        slug: '',
        siteUrl: '',
        execution: '',
        services: [],
        results: [],
        status
      });
      setServices([]);
      setToggleMDS(false);
      setBannerColor('#ffffff');
      setTypes([]);
      setIndustry([]);
      setResults([]);
    } catch (error) {
      console.error('Error adding project:', error);
    }
  };

  return (
    <div className="container mx-auto p-4 mt-12">
      <div className="flex">
        <div className="w-3/4 pr-4">
          <h1 className="text-2xl font-bold mb-4">Add New Project</h1>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                Title
              </label>
              <input
                type="text"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                id="title"
                value={formData.title}
                onChange={handleChange}
                required
              />
            </div>

            <div className="flex mb-4">
              <div className="w-1/2 pr-2">
                <label className="block text-sm font-medium text-gray-700">MDS</label>
                <label className="inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    id="mds"
                    className="sr-only peer"
                    checked={toggleMDS}
                    onChange={(e) => handleToggleMDS(e.target.checked)}
                  />
                  <div
                    className={`relative w-11 h-6 rounded-full ${
                      toggleMDS ? 'bg-green-600' : 'bg-gray-200'
                    } peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800`}
                  >
                    <div
                      className={`absolute top-0.5 ${
                        toggleMDS ? 'translate-x-full' : '-translate-x-0'
                      } left-0.5 bg-white border-gray-300 border rounded-full h-5 w-5 transition-transform`}
                    ></div>
                  </div>
                  <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                    {toggleMDS ? 'On' : 'Off'}
                  </span>
                </label>
              </div>
              <div className="w-1/2 pl-2">
                <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                  Country
                </label>
                <input
                  type="text"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                  id="country"
                  value={formData.country}
                  onChange={handleChange}
                  placeholder="Enter country"
                  required
                />
              </div>
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Project Type</label>
              <div className="mt-2 flex">
                <button
                  type="button"
                  className={`px-4 py-2 border rounded-md ${projectType === 'general' ? 'bg-blue-500 text-white' : 'bg-white border-gray-300 text-gray-700'}`}
                  onClick={() => handleProjectType('general')}
                >
                  General
                </button>
                <button
                  type="button"
                  className={`ml-2 px-4 py-2 border rounded-md ${projectType === 'website' ? 'bg-blue-500 text-white' : 'bg-white border-gray-300 text-gray-700'}`}
                  onClick={() => handleProjectType('website')}
                >
                  Website
                </button>
                <button
                  type="button"
                  className={`ml-2 px-4 py-2 border rounded-md ${projectType === 'result' ? 'bg-blue-500 text-white' : 'bg-white border-gray-300 text-gray-700'}`}
                  onClick={() => handleProjectType('result')}
                >
                  Result
                </button>
                <button
                  type="button"
                  className={`ml-2 px-4 py-2 border rounded-md ${projectType === 'service' ? 'bg-blue-500 text-white' : 'bg-white border-gray-300 text-gray-700'}`}
                  onClick={() => handleProjectType('service')}
                >
                  Service
                </button>
              </div>
            </div>

            {projectType === 'general' && (
              <div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Logo</label>
                  <input
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                    type="file"
                    id="logo"
                    onChange={handleChange}
                  />
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Client Details</label>
                  <textarea
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                    id="clientDetails"
                    value={formData.clientDetails}
                    onChange={handleChange}
                    rows="3"
                  ></textarea>
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Client Excerpt</label>
                  <textarea
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                    id="clientExcerpt"
                    value={formData.clientExcerpt}
                    onChange={handleChange}
                    rows="3"
                  ></textarea>
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Project Objective</label>
                  <textarea
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                    id="projectObjective"
                    value={formData.projectObjective}
                    onChange={handleChange}
                    rows="3"
                  ></textarea>
                </div>
              </div>
            )}

            {projectType === 'website' && (
              <div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Website Execution</label>
                  <textarea
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                    id="websiteExecution"
                    value={formData.websiteExecution}
                    onChange={handleChange}
                    rows="3"
                  ></textarea>
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Banner Landscape</label>
                  <input
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                    type="file"
                    id="banner"
                    onChange={handleChange}
                  />
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Banner Color</label>
                  <ChromePicker
                    color={bannerColor}
                    onChange={handleColorChange}
                  />
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Full Site Image</label>
                  <input
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                    type="file"
                    id="fullSiteImage"
                    onChange={handleChange}
                  />
                </div>

                <div className="mb-4">
                  <label htmlFor="slug" className="block text-sm font-medium text-gray-700">
                    Slug
                  </label>
                  <input
                    type="text"
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                    id="slug"
                    value={formData.slug}
                    onChange={handleChange}
                    placeholder="Enter slug"
                  />
                </div>

                <div className="mb-4">
                  <label htmlFor="siteUrl" className="block text-sm font-medium text-gray-700">
                    Site URL
                  </label>
                  <input
                    type="text"
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                    id="siteUrl"
                    value={formData.siteUrl}
                    onChange={handleChange}
                    placeholder="Enter site URL"
                  />
                </div>

                <div className="mb-4">
                  <label htmlFor="execution" className="block text-sm font-medium text-gray-700">
                    Execution
                  </label>
                  <textarea
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                    id="execution"
                    value={formData.execution}
                    onChange={handleChange}
                    rows="3"
                  ></textarea>
                </div>
              </div>
            )}

            {projectType === 'result' && (
                 <div className="mb-4">
                 <label className="block text-sm font-medium text-gray-700">Results</label>
                 <br />
                 <p>Title<span style={{ marginLeft: '350px' }}>Value</span><span style={{ marginLeft: '340px' }}>Unit</span></p>
                 {results.map((result, index) => (
                   <div key={index} className="flex mb-2">
                     <input
                       type="text"
                       value={result.title || ''}
                       onChange={(e) => handleResultChange(result.id, 'title', e.target.value)}
                       className="block w-full p-2 border border-gray-300 rounded-md shadow-sm mr-2"
                     />
                     <input
                       type="number"
                       value={result.value || ''}
                       onChange={(e) => handleResultChange(result.id, 'value', e.target.value)}
                       className="block w-full p-2 border border-gray-300 rounded-md shadow-sm mr-2"
                
                     />
                     <input
                       type="text"
                       value={result.unit || ''}
                       onChange={(e) => handleResultChange(result.id, 'unit', e.target.value)}
                       className="block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                  
                     />
                   </div>
                 ))}
                 <div className="flex mb-2">
                   <input
                     type="text"
                     value={newResult.title || ''}
                     onChange={(e) => handleNewResultChange('title', e.target.value)}
                     placeholder="Title"
                     className="block w-full p-2 border border-gray-300 rounded-md shadow-sm mr-2"
                   />
                   <input
                     type="number"
                     value={newResult.value || ''}
                     onChange={(e) => handleNewResultChange('value', e.target.value)}
                     placeholder="Value"
                     className="block w-full p-2 border border-gray-300 rounded-md shadow-sm mr-2"
                   />
                   <input
                     type="text"
                     value={newResult.unit || ''}
                     onChange={(e) => handleNewResultChange('unit', e.target.value)}
                     placeholder="Unit"
                     className="block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                   />
                   <button
                     type="button"
                     className="ml-2 bg-blue-500 text-white p-2 rounded-md"
                     onClick={addResult}
                   >
                     Save
                   </button>
                 </div>
               </div>
            )}

            {projectType === 'service' && (
              <div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Services</label>
                <br />
                <p>Title<span style={{ marginLeft: '255px' }}>Description</span><span style={{ marginLeft: '200px' }}>Icon URL</span><span style={{ marginLeft: '220px' }}>Images Link</span></p>
                <br />

                {services.map((service, index) => (
                  <div key={index} className="flex mb-2">
                    <input
                      type="text"
                      value={service.title || ''}
                      onChange={(e) => handleServiceChange(service.id, 'title', e.target.value)}
                      className="block w-full p-2 border border-gray-300 rounded-md shadow-sm mr-2"
                    />
                    <input
                      type="text"
                      value={service.description || ''}
                      onChange={(e) => handleServiceChange(service.id, 'description', e.target.value)}
                      className="block w-full p-2 border border-gray-300 rounded-md shadow-sm mr-2"
                    />
                    <input
                      type="text"
                      value={service.icon || ''}
                      onChange={(e) => handleServiceChange(service.id, 'icon', e.target.value)}
                      className="block w-full p-2 border border-gray-300 rounded-md shadow-sm mr-2"
                    
                    />
                    <input
                      type="text"
                      value={(service.images || []).join(', ')}
                      onChange={(e) => handleServiceChange(service.id, 'images', e.target.value.split(', '))}
                    
                    />
                  </div>
                ))}
              </div>

              <div className="mb-4">
                <h3 className="text-lg font-semibold mb-2">Add Service</h3>
                <input
                  type="text"
                  value={newService.title || ''}
                  onChange={(e) => handleNewServiceChange('title', e.target.value)}
                  placeholder="Title"
   
                />

                <input
                  type="text"
                  value={newService.description || ''}
                  onChange={(e) => handleNewServiceChange('description', e.target.value)}
                  placeholder="Description"
                  className="block w-full p-2 border border-gray-300 rounded-md shadow-sm mb-2"
           
                />

                <input
                  type="text"
                  value={newService.icon || ''}
                  onChange={(e) => handleNewServiceChange('icon', e.target.value)}
                  placeholder="Icon URL"
                  className="block w-full p-2 border border-gray-300 rounded-md shadow-sm mb-2"
           
                />

                {newService.images.map((img, index) => (
                  <div key={index} className="flex mb-2">
                    <input
                      type="text"
                      value={img || ''}
                      onChange={(e) => handleNewImageChange(index, e.target.value)}
                      placeholder={`Image URL ${index + 1}`}
                      className="block w-full p-2 border border-gray-300 rounded-md shadow-sm mr-2"
                     
                    />
                  </div>
                ))}

                <button
                  type="button"
                  onClick={addNewImage}
                  className="bg-blue-500 text-white p-2 rounded-md mb-2"
                
                >
                  Add Image
                </button>

                <button
                  type="button"
                  onClick={addService}
                  className="bg-blue-500 text-white p-2 rounded-md"
                >
                  Save
                </button>
              </div>
            </div>
            )}

            <button type="submit" className="mt-4 px-4 py-2 bg-green-500 text-white rounded-md">
              Submit
            </button>
          </form>
        </div>
        <div className="w-1/4 pl-4">
          <div className="sticky top-4">
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Type of Project</label>
              <select
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm js-example-basic-multiple-types"
                name="types[]"
                multiple="multiple"
                ref={typesRef}
              >
                {projectTypes.map(type => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </select>
              <div className="mt-2">
                <label className="block text-sm font-medium text-gray-700">New Type:</label>
                <input type='text' className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm" value={newType} onChange={(e) => setNewType(e.target.value)} />
                <button type="button" className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-md" onClick={handleAddType}>Add Type</button>
              </div>
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Industry</label>
              <select
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm js-example-basic-multiple-industry"
                name="industry[]"
                multiple="multiple"
                ref={industriesRef}
              >
                {industries.map(industry => (
                  <option key={industry} value={industry}>{industry}</option>
                ))}
              </select>
              <div className="mt-2">
                <label className="block text-sm font-medium text-gray-700">New Industry:</label>
                <input type='text' className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm" value={newIndustry} onChange={(e) => setNewIndustry(e.target.value)} />
                <button type="button" className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-md" onClick={handleAddIndustry}>Add Industry</button>
              </div>
            </div>
            <div className="mt-2">
  <label htmlFor="status" className="block text-sm font-medium text-gray-700">
    Status:
  </label>
  <select
    id="status"
    value={status} 
    onChange={handleStatusChange}
    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
  >
    <option value="Publish">Publish</option>
    <option value="Draft">Draft</option>
  </select>
</div>


          </div>
        </div>
      </div>
    </div>
  );
};

export default function App() {
  return (
    <FormProvider>
      <AddProjectForm />
    </FormProvider>
  );
}
