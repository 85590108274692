import React, { useState } from 'react';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import BaseURL from '../../../components/Other/BaseURL';

const FileUpload = () => {
  const tabFeature = 'upload';
  const [files, setFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});

  const onDrop = acceptedFiles => {
    setFiles(acceptedFiles);
    setUploadProgress({});
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: true });

  const handleFileUpload = async () => {
    const formData = new FormData();
    files.forEach(file => {
      formData.append('files', file);
    });

    try {
      const response = await axios.post(`https://wodo.digital/media/blogs/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const progress = Math.round((loaded * 100) / total);
          setUploadProgress(prevProgress => {
            const updatedProgress = {};
            files.forEach(file => {
              updatedProgress[file.name] = progress;
            });
            return { ...prevProgress, ...updatedProgress };
          });
        },
      });

      console.log('File uploaded successfully:', response.data);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: tabFeature === 'upload' ? 'block' : 'none',
      }}
    >
      <div
        {...getRootProps()}
        style={{
          border: '2px dashed #cccccc',
          padding: '20px',
          width: '300px',
          textAlign: 'center',
        }}
      >
        <input {...getInputProps()} />
        <h6>Drop files to upload</h6>
        <h6>or</h6>
        <p>Maximum upload file size: 1 GB.</p>
      </div>
      {files.length > 0 && (
        <div style={{ marginTop: '20px' }}>
          <h6>Files to be uploaded:</h6>
          <ul>
            {files.map((file, index) => (
              <li key={index}>
                {file.name} - {file.size} bytes
                {uploadProgress[file.name] !== undefined && (
                  <div style={{ width: '100%', backgroundColor: '#ccc', marginTop: '5px' }}>
                    <div
                      style={{
                        width: `${uploadProgress[file.name]}%`,
                        backgroundColor: '#4caf50',
                        height: '5px',
                      }}
                    ></div>
                  </div>
                )}
              </li>
            ))}
          </ul>
          <button onClick={handleFileUpload}>Upload Files</button>
        </div>
      )}
    </div>
  );
};

export default FileUpload;


// import React, { useState } from 'react';
// import axios from 'axios';
// import { useDropzone } from 'react-dropzone';
// import BaseURL from '../../../components/Other/BaseURL';

// const FileUpload = () => {
//   const tabFeature='upload'
//   const [files, setFiles] = useState([]);
//   const [uploadProgress, setUploadProgress] = useState({});

//   const onDrop = acceptedFiles => {
//     setFiles(acceptedFiles);
//   };

//   const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: true });

//   const handleFileUpload = async () => {
//     const formData = new FormData();
//     files.forEach(file => {
//       formData.append('files', file);
//     });

//     try {
//       const response = await axios.post(BaseURL+'/upload-media-file/upload', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//         onUploadProgress: (progressEvent) => {
//           const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
//           setUploadProgress({ ...uploadProgress, [files.name]: progress });
//         },
//       });

//       console.log('File uploaded successfully:', response.data);
//     } catch (error) {
//       console.error('Error uploading file:', error);
//     }
//   };

//   return (
//     <div
//       style={{
//         position: 'absolute',
//         top: '50%',
//         left: '50%',
//         transform: 'translate(-50%, -50%)',
//         display: tabFeature === 'upload' ? 'block' : 'none',
//       }}
//     >
//       <div
//         {...getRootProps()}
//         style={{
//           border: '2px dashed #cccccc',
//           padding: '20px',
//           width: '300px',
//           textAlign: 'center',
//         }}
//       >
//         <input {...getInputProps()} />
//         <h6>Drop files to upload</h6>
//         <h6>or</h6>
//         <p>Maximum upload file size: 1 GB.</p>
//       </div>
//       {files.length > 0 && (
//         <div style={{ marginTop: '20px' }}>
//           <h6>Files to be uploaded:</h6>
//           <ul>
//             {files.map((file, index) => (
//               <li key={index}>
//                 {file.name} - {file.size} bytes
//                 {uploadProgress[file.name] && (
//                   <div style={{ width: '100%', backgroundColor: '#ccc' }}>
//                     <div
//                       style={{
//                         width: `${uploadProgress[file.name]}%`,
//                         backgroundColor: '#4caf50',
//                         height: '5px',
//                       }}
//                     ></div>
//                   </div>
//                 )}
//               </li>
//             ))}
//           </ul>
//           <button onClick={handleFileUpload}>Upload Files</button>
//         </div>
//       )}
//     </div>
//   );
// };

// export default FileUpload;
