import { useParams } from 'react-router-dom';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'axios';
import Select from 'react-select';  // Import react-select
import BaseURL from '../../components/Other/BaseURL';
import folderIcon from '../../assets/folder.png';  // Add a folder icon image to your project
import fileIcon from '../../assets/image.png';  // Add a default file icon image to your project

function EditBlog(props) {
  const { slug } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [editorData, setEditorData] = useState('');
  const selectCategoriesRef = useRef(null);
  const selectTagsRef = useRef(null);

  const [BlogTitle, setBlogTitle] = useState('');
  const [BlogContent, setBlogContent] = useState('');
  const [BlogCategory, setBlogCategory] = useState('');
  const [BlogTags, setBlogTags] = useState([]);  // Initialize as an array
  const [BlogAuthor, setBlogAuthor] = useState('');
  const [BlogDescription, setBlogDescription] = useState('');

  const [selectedFile, setSelectedFile] = useState(null);

  const DataEditRefresh = useCallback(async () => {
    await axios.get(`${BaseURL}/get-all-blogs/${slug}`)
      .then((res) => {
        setBlogTitle(res.data.data[0].title);
        setBlogContent(res.data.data[0].body);
        setBlogDescription(res.data.data[0].description);
        setBlogCategory(res.data.data[0].categories);
        setBlogTags(Array.isArray(res.data.data[0].tags) ? res.data.data[0].tags : []);  // Ensure BlogTags is an array
        setBlogAuthor(res.data.data[0].author);
      })
      .catch((err) => { console.log(err); });
  }, [slug]);

  useEffect(() => {
    DataEditRefresh();
  }, [DataEditRefresh]);

  function Header() {
    return (
      <>
        <div className='fs16'>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Link to='/blogs' className='pt-1 pe-4'>
                <i className="fas fa-caret-left" style={{ fontSize: 20, color: '#000' }}></i>
              </Link>
              <h1 className='font-bold text-2xl'>Edit Blog</h1>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
            <button className="bg-blue-500 text-white text-sm px-4 py-2 rounded hover:bg-blue-600">Save Draft</button>
                <button className="bg-gray-500 text-white text-sm px-4 py-2 rounded hover:bg-gray-600">View</button>
                <button className="bg-yellow-500 text-white text-sm px-4 py-2 rounded hover:bg-yellow-600">Publish</button>

            </div>

          </div>
            <input type="text" placeholder='Add Title' value={BlogTitle} id='blog_title' className='bg-none border-none text-2xl w-full' />
        </div>
      </>
    );
  }

  function AddArticle() {
    return (
      <>
        <div className="row">
          <div className="col-md">
            {/* <input type="text" placeholder='Add Title' value={BlogTitle} id='blog_title' className='bg-none border-none text-2xl w-full' /> */}

            <div className="row">
              <div className="col">
                <CKeditorBlog />
              </div>

              {/* <div className="col-md-2">
                <PostAddBlog blogtitle={BlogTitle} description={BlogDescription} categories={BlogCategory} author={BlogAuthor} />
              </div> */}
            </div>
          </div>
        </div>
      </>
    );
  }

  function CKeditorBlog() {
    const editorConfiguration = {};

    return (
      <>
        <div>
          <CKEditor
            editor={ClassicEditor}
            data={BlogContent}
            config={editorConfiguration}
            onReady={(editor) => {
              console.log('Editor is ready to use!', editor);
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              setBlogContent(data);
            }}
            onBlur={(event, editor) => {
              console.log('Blur.', editor);
            }}
            onFocus={(event, editor) => {
              console.log('Focus.', editor);
            }}
          />
        </div>
      </>
    );
  }

  function ModalFeaturedImage() {
    const [tabFeature, setTabFeature] = useState('upload');
    const [files, setFiles] = useState([]);
    const [currentPath, setCurrentPath] = useState('');
    const [file, setFile] = useState(null);
    const [uploadStatus, setUploadStatus] = useState('');

    useEffect(() => {
      axios.get(`http://localhost:3001/media-files/${currentPath}`)
        .then(response => {
          setFiles(response.data);
        })
        .catch(error => {
          console.error('Error fetching media files:', error);
        });
    }, [currentPath]);

    const handleFolderClick = (folderPath) => {
      setCurrentPath(folderPath);
    };

    const handleBackClick = () => {
      const parentPath = currentPath.split('/').slice(0, -1).join('/');
      setCurrentPath(parentPath);
    };

    const handleFileClick = (file) => {
      setSelectedFile(file);
    };

    const handleFileChange = (event) => {
      setFile(event.target.files[0]);
    };

    const handleFileUpload = async () => {
      const formData = new FormData();
      formData.append('file', file);

      try {
        const response = await axios.post(`https://wodo.digital/media/blogs/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        setUploadStatus(response.data);
      } catch (error) {
        setUploadStatus('Upload failed.');
        console.error('Error:', error);
      }
    };

    return (
      <>
        {showModal && (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-4 rounded shadow-lg w-full max-w-6xl"> {/* Increased size */}
              <div className="flex justify-between items-center border-b pb-2 mb-2">
                <h2 className="text-lg font-semibold">Featured Image</h2>
                <button
                  className="text-gray-600 hover:text-gray-900"
                  onClick={() => setShowModal(false)}
                >
                  <i className="fas fa-times text-xl"></i>
                </button>
              </div>
              <div className="mb-4">
                <div className="flex mb-4">
                  <button
                    onClick={() => setTabFeature('upload')}
                    className={`py-2 px-4 ${tabFeature === 'upload' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-600'} rounded-l`}
                  >
                    Upload
                  </button>
                  <button
                    onClick={() => setTabFeature('media')}
                    className={`py-2 px-4 ${tabFeature === 'media' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-600'} rounded-r`}
                  >
                    Media Files
                  </button>
                </div>
                {/* Upload Section */}
                <div className={tabFeature === 'upload' ? 'block' : 'hidden'}>
                  <div className="text-center">
                    <h6 className="mb-2">Drop files to upload</h6>
                    <h6 className="mb-4">or</h6>
                    <input
                      type="file"
                      onChange={handleFileChange}
                      className="mb-4"
                    />
                    <button
                      onClick={handleFileUpload}
                      className="bg-blue-500 text-white px-4 py-2 rounded"
                    >
                      Upload
                    </button>
                    <p className="mt-2">Maximum upload file size: 1 GB.</p>
                  </div>
                </div>
                {/* Media Files Section */}
                <div className={tabFeature === 'media' ? 'block' : 'hidden'}>
                  <div className="flex mb-2 items-center">
                    {currentPath && (
                      <button
                        onClick={handleBackClick}
                        className="bg-gray-300 px-2 py-1 rounded"
                      >
                        Back
                      </button>
                    )}
                    <span className="ml-2">{`/${currentPath}`}</span>
                  </div>
                  <div className="grid grid-cols-4 gap-4 max-h-60 overflow-y-auto">
                    {files.map((file, index) => (
                      <div
                        key={index}
                        className="p-2 border border-gray-300 rounded cursor-pointer"
                        onClick={() => file.type === 'folder' ? handleFolderClick(file.path) : handleFileClick(file)}
                      >
                        {file.type === 'folder' ? (
                          <img src={folderIcon} alt="Folder" className="w-12 h-12 object-cover" />
                        ) : (
                          <img src={fileIcon} alt="File" className="w-12 h-12 object-cover" />
                        )}
                        <p className="text-xs mt-1 truncate">{file.name}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="flex justify-end">
                <button
                  onClick={() => setShowModal(false)}
                  className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
                >
                  Cancel
                </button>
                <button
                  onClick={() => setShowModal(false)}
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  function PostAddBlog({ blogtitle, description, categories, author }) {
    const [categoriesOptions, setCategoriesOptions] = useState([]);
    const [tagsOptions, setTagsOptions] = useState([]);
    const [postCategories, setPostCategories] = useState(categories);
    const [postAuthor, setPostAuthor] = useState(author);

    useEffect(() => {
      axios.get(`${BaseURL}/categories`)
        .then(response => {
          setCategoriesOptions(response.data);
        })
        .catch(error => {
          console.error('Error fetching categories:', error);
        });

      axios.get(`${BaseURL}/tags`)
        .then(response => {
          setTagsOptions(response.data);
        })
        .catch(error => {
          console.error('Error fetching tags:', error);
        });
    }, []);

    return (
      <div className="space-y-4">
        {/* Category */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">Category</label>
          <Select
            ref={selectCategoriesRef}
            value={categoriesOptions.find(option => option.value === BlogCategory)}
            onChange={(selectedOption) => setBlogCategory(selectedOption.value)}
            options={categoriesOptions.map(category => ({ value: category.id, label: category.name }))}
            className="block w-full border-gray-300 rounded-md shadow-sm"
          />
        </div>
        {/* Tags */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">Tags</label>
          <Select
            ref={selectTagsRef}
            value={Array.isArray(BlogTags) ? BlogTags.map(tagId => tagsOptions.find(option => option.value === tagId)) : []}  // Ensure BlogTags is an array
            onChange={(selectedOptions) => setBlogTags(selectedOptions.map(option => option.value))}
            options={tagsOptions.map(tag => ({ value: tag.id, label: tag.name }))}
            isMulti
            className="block w-full border-gray-300 rounded-md shadow-sm"
          />
        </div>
        {/* Author */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">Author</label>
          <input
            type="text"
            value={BlogAuthor}
            onChange={(e) => setBlogAuthor(e.target.value)}
            className="block w-full border-gray-300 rounded-md shadow-sm"
          />
        </div>
        {/* Description */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">Description</label>
          <textarea
            value={BlogDescription}
            onChange={(e) => setBlogDescription(e.target.value)}
            className="block w-full border-gray-300 rounded-md shadow-sm"
          />
        </div>
        {/* Featured Image */}
        <div className="mb-4">
          <button
            onClick={() => setShowModal(true)}
            className="bg-blue-500 text-white px-4 py-2 rounded-md"
          >
            Set Featured Image
          </button>
          {selectedFile && (
            <div className="mt-4">
              <img
                src={`${BaseURL}/${selectedFile.path}`}
                alt={selectedFile.name}
                className="w-32 h-32 object-cover"
              />
              <p className="text-xs mt-2">{selectedFile.name}</p>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <>
      <Header />
      <div className="container mx-auto my-4">
        <div className="flex">
          <div className="flex-1">
            <AddArticle />
          </div>
          <div className="w-1/4 pl-4 sticky top-20 mt-5">
              <PostAddBlog 
                blogtitle={BlogTitle} 
                description={BlogDescription} 
                categories={BlogCategory} 
                author={BlogAuthor} 
              />
            </div>


        </div>
      </div>
      <ModalFeaturedImage />
    </>
  );
}

export default EditBlog;
