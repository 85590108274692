import React,{useState,useEffect,useRef,useCallback} from 'react'
import $ from 'jquery';
import './style.css'

function PostAddBlog(props) {
    const selectCategoriesRef = useRef(null);
    const selectTagsRef = useRef(null);
    const [postOpen, setpostOpen] = useState('Post')
    


    useEffect(() => {
        console.log(postOpen)
    }, [postOpen])
    
    function AccordionItem(props){
        return(
            <>
                <div className="accordion-item" >
                    <h2 className="accordion-header">
                        <button onClick={() => { props.setpostOpen(props.title) }}  className={`accordion-button ${props.collapsed?'':'collapsed'}`} type="button" data-bs-toggle="collapse" data-bs-target={`#`+props.id} aria-expanded="true" aria-controls={props.id}>
                            {props.title}
                        </button>
                    </h2>
                    <div id={props.id} className={`accordion-collapse collapse  ${props.show?'show':''}`} data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            {props.body}
                        </div>
                    </div>
                </div>
            </>
        );
    }

    function SelectCategories(){
        
        // console.log(props.categories);

        const optionsData = [
            { value: 'Blog', label: 'Blog', select: false },
            { value: 'Design Tips', label: 'Design Tips', select: false },
            { value: 'Digital Dairy', label: 'Digital Dairy', select: false },
            { value: 'Marketing Guide', label: 'Marketing Guide', select: false },
            { value: 'SEO', label: 'SEO', select: false },
            { value: 'Social Media', label: 'Social Media', select: false },
            { value: 'Uncategorized', label: 'Uncategorized', select: false },
            { value: 'Website', label: 'Website', select: false },
            { value: 'WordPress', label: 'WordPress', select: false }
        ];

        const selectedValues = props.categories;

        optionsData.forEach(option => {
            if (selectedValues.includes(option.value)) {
                option.select = true;
            }
        });


        useEffect(() => {
            $(selectCategoriesRef.current).select2();
        }, []);
        

        return(
            <>
                <select
                    ref={selectCategoriesRef}
                    className='selectCategories'
                    name="categories[]"
                    multiple="multiple"
                    style={{width:'100%'}}
                    placeholder='Select Categories'
                >
                    
                    <option defaultValue="" disabled>Select Categories</option>
                    {optionsData.map(option => (
                        <option 
                            key={option.value} 
                            value={option.value}
                            selected={option.select}
                        >
                            {option.label}
                        </option>
                    ))}
                    

                </select>
            </>
        );
    }


    function SelectTags(){
        
        const optionsData = [
            { value: 'Blog', label: 'Blog', select: false },
            { value: 'Design Tips', label: 'Design Tips', select: false },
            { value: 'Digital Dairy', label: 'Digital Dairy', select: false },
            { value: 'Marketing Guide', label: 'Marketing Guide', select: false },
            { value: 'SEO', label: 'SEO', select: false },
            { value: 'Social Media', label: 'Social Media', select: false },
            { value: 'Uncategorized', label: 'Uncategorized', select: false },
            { value: 'Website', label: 'Website', select: false },
            { value: 'WordPress', label: 'WordPress', select: false }
        ];

        useEffect(() => {
            $(selectTagsRef.current).select2();
        }, []);
        

        return(
            <>
                <select
                    ref={selectTagsRef}
                    className='selectTags'
                    name="tags[]"
                    multiple="multiple"
                    style={{width:'100%'}}
                    placeholder='Select Tags'
                >
                    
                    <option defaultValue="" disabled>Select Tags</option>
                    {optionsData.map(option => (
                        <option 
                            key={option.value} 
                            value={option.value}
                            selected={option.select}
                        >
                            {option.label}
                        </option>
                    ))}
                    

                </select>
            </>
        );
    }


  return (
    <>
        <div className="accordion" id="accordionExample">
            <AccordionItem title='Post' id='collapsePost' 
            body={
                <>
                    <div className="row">
                        <div className="col verticle_center">Visibility</div>
                        <div className="col">
                            <select name="" className='form-control' id="">
                                <option value="">Public</option>
                                <option value="">Private</option>
                            </select>
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col">Publish</div>
                        <div className="col">Immediately</div>
                    </div> */}
                </>
            } show={postOpen==='Post'?true:false} collapsed={postOpen==='Post'?true:false} setpostOpen={setpostOpen} />

            <AccordionItem title='Categories' id='collapseCategories' body={
                <>
                    <label htmlFor="selectCategories">Categories</label>
                    <SelectCategories id="selectCategories"/>
                    {/* <SelectCategoriesV2/> */}
                </>
            } show={postOpen==='Categories'?true:false} collapsed={postOpen==='Categories'?true:false} setpostOpen={setpostOpen}/>
            
            <AccordionItem title='Tags' id='collapseTags' body={
                <>
                    <label htmlFor="selectTag">Tags</label>
                    <SelectTags id="selectTag"/>
                </>
            } show={postOpen==='Tags'?true:false} collapsed={postOpen==='Tags'?true:false}  setpostOpen={setpostOpen}/>

            <AccordionItem title='Meta Data' id='collapseMeta' body={
                <>
                    <label htmlFor="titleId" className="form-label">Title</label>
                    <input className="form-control form-control-sm" defaultValue={props.blogtitle} readOnly type="text" id='titleId' placeholder="Title" aria-label=".form-control-sm example"/>
                    
                    <label htmlFor="descriptionId" className="form-label">Description</label>
                    <textarea className="form-control form-control-sm" type="text" value={props.description} id='descriptionId' placeholder="Description" aria-label=".form-control-sm example"></textarea>
                    
                    <label htmlFor="authorId" className="form-label">Author</label>
                    {/* <select id='authorId' className="form-control form-control-sm" name="" >
                        <option value="" selected disabled>Select Author</option>
                        <option defaultValue="">Author 1</option>
                        <option defaultValue="">Author 2</option>
                        <option defaultValue="">Author 3</option>
                    </select> */}
                    <input type="text" value={props.author} />
                    
                </>
            } show={postOpen==='Meta Data'?true:false} collapsed={postOpen==='Meta Data'?true:false} setBlogtitle={props.setBlogtitle} setpostOpen={setpostOpen}/>

            <AccordionItem title='Featured Image' id='collapseFeaturedImage' body={
                <>
                    <div data-bs-toggle="modal" data-bs-target="#FeaturedImageModal" style={{width:'100%',height:100,background:'grey',color:'#fff',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                        Featured image
                    </div>
                </>
            } show={postOpen==='Featured Image'?true:false} collapsed={postOpen==='Featured Image'?true:false} setpostOpen={setpostOpen}/>
        </div>
    </>
  )
}

export default PostAddBlog