import React, { useState, useEffect } from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import { ChromePicker } from 'react-color';
import axios from 'axios';
import 'select2/dist/css/select2.min.css';
import 'select2';
import $, { type } from 'jquery';


const BaseURL = 'https://server-demo.wodo.digital';

const ProjectDetails = () => {
  const { slug } = useParams();
  const [project, setProject] = useState(null);
  const [projectType, setProjectType] = useState('general');
  const [isEditing, setIsEditing] = useState(false);
  const [bannerColor, setBannerColor] = useState('#ffffff');
  const [results, setResults] = useState([]);
  const [newResult, setNewResult] = useState({ title: '', value: '', unit: '' });
  const [newService, setNewService] = useState({ title: '', description: '', icon: '', images: [''] });
  //const [types, setTypes] = useState([]);
  //const [industry, setIndustry] = useState([]);
  const [services, setServices] = useState([]);
  //const [industries, setIndustries] = useState([]);
  //const [projectTypes, setProjectTypes] = useState([]);
  const [projectTypes, setProjectTypes] = useState([]);
  const [types, setTypes] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [formData, setFormData] = useState({ types: [], industries: [] });
  const [status, setStatus] = useState(); 


  const navigate = useNavigate();
  

  const fetchTypesAndIndustries = async () => {
    try {
      const typesResponse = await axios.get(`${BaseURL}/projecttype`);
      const industriesResponse = await axios.get(`${BaseURL}/industrytype`);

      const typesData = typesResponse.data.map(type => type.type_name);
      const industriesData = industriesResponse.data.map(industry => industry.type_name);

      setProjectTypes(typesData);
      setIndustries(industriesData);
      

    } catch (error) {
      console.error('Error fetching project types and industries:', error);
    }
  };




  const initializeSelect2 = () => {
    $('.js-example-basic-multiple-types').select2({
      tags: true,
      placeholder: 'Select types',
    }).val(types).trigger('change');

    $('.js-example-basic-multiple-industry').select2({
      tags: true,
      placeholder: 'Select industries',
    }).val(industry).trigger('change');
  };

  useEffect(() => {
    fetchTypesAndIndustries();
  }, []);

  useEffect(() => {
    if (projectTypes.length > 0 && industries.length > 0) {
      initializeSelect2();
    }
  }, [projectTypes, industries, types, industry]);


 
  useEffect(() => {
    console.log('Slug:', slug); // Log slug to ensure it's being received correctly

    const fetchProjectDetails = async () => {
      if (!slug) {
        console.error('Slug is undefined');
        return;
      }

      try {
        const response = await axios.get(`${BaseURL}/projects/slug/${slug}`);
        const data = response.data;
        setProject(data);
        setBannerColor(data.bannerColour || '#ffffff');
        let parsedResults = [];
        let parsedServices = [];
        let parsedTypes = [];
        let parsedIndustry = [];

        try {
          parsedResults = JSON.parse(data.results);
          parsedServices = JSON.parse(data.services);
          parsedTypes = JSON.parse(data.type);
          parsedIndustry = JSON.parse(data.industry);
        } catch (error) {
          console.error('Error parsing results JSON:', error);
          console.log('Data is plain string, not JSON');
          parsedTypes = data.type ? data.type.split(',').map(item => item.trim()) : [];
          parsedIndustry = data.industry ? data.industry.split(',').map(item => item.trim()) : [];
        }
        
        setResults(Array.isArray(parsedResults) ? parsedResults : []);
        setServices(Array.isArray(parsedServices) ? parsedServices : []);
        setProjectTypes(data.projectTypes || []);
        setIndustries(data.industries || []);
        setTypes(Array.isArray(parsedTypes) ? parsedTypes : []);
        setIndustry(Array.isArray(parsedIndustry) ? parsedIndustry : []);
        console.log(types);
        
      } catch (error) {
        console.error('Error fetching project details:', error);
      }
    };

    fetchProjectDetails();
  }, [slug]);

  const handleChange = (e) => {
    const { id, value, type, checked, files } = e.target;
    if (type === 'file') {
      setProject(prevProject => ({
        ...prevProject,
        [id]: files[0]
      }));
    } else {
      setProject(prevProject => ({
        ...prevProject,
        [id]: type === 'checkbox' ? checked : value
      }));
    }
  };

  const handleColorChange = (color) => {
    setBannerColor(color.hex);
  };
  const handleSave = async () => {
    const selectedTypes = $('.js-example-basic-multiple-types').val() || [];
    const selectedIndustries = $('.js-example-basic-multiple-industry').val() || [];
  
    const selectedTypesArray = Array.isArray(selectedTypes) ? selectedTypes : [selectedTypes];
    const selectedIndustriesArray = Array.isArray(selectedIndustries) ? selectedIndustries : [selectedIndustries];

    console.log('Selected Types:', selectedTypesArray);
    console.log('Selected Industries:', selectedIndustriesArray);
    console.log('Existing Types:', types);
    console.log('Existing Industries:', industry);
  
    const updatedTypes = selectedTypesArray.length > 0 ? Array.from(new Set([...types, ...selectedTypesArray])) : types;
    const updatedIndustries = selectedIndustriesArray.length > 0 ? Array.from(new Set([...industry, ...selectedIndustriesArray])) : industry;
    
   
    console.log('Updated Types:', updatedTypes);
    console.log('Updated Industries:', updatedIndustries);
    
    try {
      await axios.put(`${BaseURL}/projects/slug/${slug}`, {
        ...project,
        bannerColour: bannerColor,
        results,
        services,
        type: updatedTypes,
        industry: updatedIndustries,
        status
      });
      alert('Data is Updated Successfully');
      setIsEditing(false);
    } catch (error) {
      console.error('Error saving project details:', error);
    }
  };
  
  
  const handleEdit = () => {
    setIsEditing(true);
  };
  

  const handleProjectTypeChange = (e) => {
    setProject(prevProject => ({
      ...prevProject,
      type: e.value
    }));
  };


  const handleResultChange = (index, field, value) => {
    const updatedResults = [...results];
    updatedResults[index][field] = value;
    setResults(updatedResults);
  };
  const handleStatusChange = (e) => {
    setProject((prevProject) => ({
      ...prevProject,
      status: e.target.value,
    }));
  };
  
  const handleNewResultChange = (field, value) => {
    setNewResult(prevState => ({
      ...prevState,
      [field]: value
    }));
  };

  const addResult = () => {
    const id = results.length + 1;
    const resultWithId = { id, ...newResult };
    setResults(prevResults => [...prevResults, resultWithId]);
    setNewResult({ title: '', value: '', unit: '' });
  };

  const handleProjectType = (type) => {
    setProjectType(type);
  };

  const handleServiceChange = (index, field, value) => {
    const updatedServices = [...services];
    updatedServices[index][field] = value;
    setServices(updatedServices);
  };

  const handleNewServiceChange = (field, value) => {
    setNewService({ ...newService, [field]: value });
  };
  const handleTypeChange = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
    setTypes(selectedOptions);
  };
  

  const handleIndustryChange = (event) => {
    setIndustry($(event.target).val());
  };
  const addService = () => {
    const id = services.length + 1;
    setServices([...services, { id, ...newService, images: [...newService.images] }]);
    setNewService({ title: '', description: '', icon: '', images: [''] });
  };

const handleDelete = async (projectSlug) => {
  const confirmDelete = window.confirm("Are you sure you want to delete this project?");
  if (!confirmDelete) return;

  try {
    const response = await axios.delete(`${BaseURL}/projects/delete/${projectSlug}`);
    if (response.status === 200) {
      alert('Project deleted successfully');
      navigate('/projects/all-projects')
    }
  } catch (error) {
    console.error('Error deleting project:', error);
    alert('Failed to delete the project');
  }
};


  // const handleImageChange = (index, imgIndex, value) => {
  //   const updatedServices = services.map((service, sIndex) =>
  //     sIndex === index
  //       ? { ...service, images: service.images.map((img, iIndex) => iIndex === imgIndex ? value : img) }
  //       : service
  //   );
  //   setServices(updatedServices);
  // };

  const handleNewImageChange = (index, value) => {
    setNewService(prev => ({
      ...prev,
      images: prev.images.map((img, iIndex) => iIndex === index ? value : img)
    }));
  };

  const addNewImage = () => {
    setNewService(prev => ({ ...prev, images: [...prev.images, ''] }));
  };

  if (!project) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto p-4 mt-12">
      <div className="flex">
        <div className="w-3/4 pr-4">
          <h1 className="text-2xl font-bold mb-4">Project Details</h1>
          <form>
            <div className="mb-4">
              <label htmlFor="title" className="block text-sm font-medium text-gray-700">Title</label>
              <input
                type="text"
                id="title"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                value={project.title || ''}
                onChange={handleChange}
                disabled={!isEditing}
              />
            </div>

            <div className="flex mb-4">
              <div className="w-1/2 pr-2">
                <label htmlFor="mds" className="block text-sm font-medium text-gray-700">MDS</label>
                <input
                  type="checkbox"
                  id="mds"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                  checked={project.mds || false}
                  onChange={handleChange}
                  disabled={!isEditing}
                />
              </div>
              <div className="w-1/2 pl-2">
                <label htmlFor="country" className="block text-sm font-medium text-gray-700">Country</label>
                <input
                  type="text"
                  id="country"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                  value={project.country || ''}
                  onChange={handleChange}
                  disabled={!isEditing}
                />
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Project Type</label>
              <div className="mt-2 flex">
                <button
                  type="button"
                  className={`px-4 py-2 border rounded-md ${projectType === 'general' ? 'bg-blue-500 text-white' : 'bg-white border-gray-300 text-gray-700'}`}
                  onClick={() => handleProjectType('general')}
                >
                  General
                </button>
                <button
                  type="button"
                  className={`ml-2 px-4 py-2 border rounded-md ${projectType === 'website' ? 'bg-blue-500 text-white' : 'bg-white border-gray-300 text-gray-700'}`}
                  onClick={() => handleProjectType('website')}
                >
                  Website
                </button>
                <button
                  type="button"
                  className={`ml-2 px-4 py-2 border rounded-md ${projectType === 'result' ? 'bg-blue-500 text-white' : 'bg-white border-gray-300 text-gray-700'}`}
                  onClick={() => handleProjectType('result')}
                >
                  Result
                </button>
                <button
                  type="button"
                  className={`ml-2 px-4 py-2 border rounded-md ${projectType === 'service' ? 'bg-blue-500 text-white' : 'bg-white border-gray-300 text-gray-700'}`}
                  onClick={() => handleProjectType('service')}
                >
                  Service
                </button>
              </div>
            </div>
            
            {/* <div className="mb-4">
              <label htmlFor="type" className="block text-sm font-medium text-gray-700">Project Type</label>
              <Select
                value={projectTypes.find(option => option.value === project.type)}
                onChange={handleProjectTypeChange}
                options={projectTypes.map(type => ({ value: type, label: type }))}
                isDisabled={!isEditing}
              />
            </div> */}
            {projectType === 'general' && (<>
              <div className="mb-4">
      <label htmlFor="logo" className="block text-sm font-medium text-gray-700">Logo</label>
      {project.logo && (
        <div className="mb-4">
           <input
        type="text"
        id="logo"
        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
        value={project.logo || ''}
        onChange={handleChange}
        disabled={!isEditing}
      />
        </div>
      )}
      <input
        type="file"
        id="logo"
        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
        onChange={handleChange}
        disabled={!isEditing}
      />
    </div>

            <div className="mb-4">
              <label htmlFor="clientDetails" className="block text-sm font-medium text-gray-700">Client Details</label>
              <textarea
                id="clientDetails"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                value={project.clientDetails || ''}
                onChange={handleChange}
                rows="3"
                disabled={!isEditing}
              ></textarea>
            </div>

            <div className="mb-4">
              <label htmlFor="clientExcerpt" className="block text-sm font-medium text-gray-700">Client Excerpt</label>
              <textarea
                id="clientExcerpt"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                value={project.clientExcerpt || ''}
                onChange={handleChange}
                rows="3"
                disabled={!isEditing}
              ></textarea>
            </div>

            <div className="mb-4">
              <label htmlFor="projectObjective" className="block text-sm font-medium text-gray-700">Project Objective</label>
              <textarea
                id="projectObjective"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                value={project.projectObjective || ''}
                onChange={handleChange}
                rows="3"
                disabled={!isEditing}
              ></textarea>
            </div>
            </>
            )}
            {projectType === 'website' && (
              <div>
                <div className="mb-4">
                  <label htmlFor="websiteExecution" className="block text-sm font-medium text-gray-700">Website Execution</label>
                  <textarea
                    id="websiteExecution"
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                    value={project.websiteExecution || ''}
                    onChange={handleChange}
                    rows="3"
                    disabled={!isEditing}
                  ></textarea>
                </div>
                <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Banner Landscape</label>
                <input
               type="text"
                id="banner"
               className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
              value={project.banner || ''}
              onChange={handleChange}
               disabled={!isEditing}
      />
                <input
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                  type="file"
                  id="banner"
                  onChange={handleChange}
                  disabled={!isEditing}
                />
              </div>
                <div className="mb-4">
              <label htmlFor="bannerColor" className="block text-sm font-medium text-gray-700">Banner Color</label>
              <ChromePicker
                color={bannerColor}
                onChangeComplete={handleColorChange}
                disabled={!isEditing}
              />
            </div>
            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Full Site Image</label>
                          <input
                  type="text"
                  id="fullSiteImage"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                  value={project.fullSiteImage || ''}
                  onChange={handleChange}
                  disabled={!isEditing}
                />
                <input
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                  type="file"
                  id="fullSiteImage"
                //   value={project.fullSiteImage}
                  onChange={handleChange}
                  disabled={!isEditing} 
                />
              </div>

              <div className="mb-4">
                <label htmlFor="slug" className="block text-sm font-medium text-gray-700">Slug</label>
                <input
                  type="text"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                  id="slug"
                  value={project.slug || ''}
                  onChange={handleChange}
                  placeholder="Enter slug"
                  disabled={!isEditing}
                />
              </div>


                <div className="mb-4">
                  <label htmlFor="siteUrl" className="block text-sm font-medium text-gray-700">Site URL</label>
                  <input
                    type="text"
                    id="siteUrl"
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                    value={project.siteUrl || ''}
                    onChange={handleChange}
                    disabled={!isEditing}
                  />
                </div>

                <div className="mb-4">
                <label htmlFor="execution" className="block text-sm font-medium text-gray-700">Execution</label>
                <textarea
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                  id="execution"
                  value={project.websiteExecution || ''}
                  onChange={handleChange}
                  rows="3"
                  disabled={!isEditing}
                ></textarea>
              </div>
              </div>
            )}

            {projectType === 'result' && (
              <div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Results</label>
                  <br />
                  <p>Title<span style={{ marginLeft: '350px' }}>Value</span><span style={{ marginLeft: '340px' }}>Unit</span></p>
                  {results.map((result, index) => (
                    <div key={index} className="flex mb-2">
                      <input
                        type="text"
                        value={result.title || ''}
                        onChange={(e) => handleResultChange(result.id, 'title', e.target.value)}
                        className="block w-full p-2 border border-gray-300 rounded-md shadow-sm mr-2"
                        disabled={!isEditing}
                      />
                      <input
                        type="number"
                        value={result.value || ''}
                        onChange={(e) => handleResultChange(result.id, 'value', e.target.value)}
                        className="block w-full p-2 border border-gray-300 rounded-md shadow-sm mr-2"
                        disabled={!isEditing}
                      />
                      <input
                        type="text"
                        value={result.unit || ''}
                        onChange={(e) => handleResultChange(result.id, 'unit', e.target.value)}
                        className="block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                        disabled={!isEditing}
                      />
                    </div>
                  ))}
                  <div className="flex mb-2">
                    <input
                      type="text"
                      value={newResult.title || ''}
                      onChange={(e) => handleNewResultChange('title', e.target.value)}
                      placeholder="Title"
                      className="block w-full p-2 border border-gray-300 rounded-md shadow-sm mr-2"
                    />
                    <input
                      type="number"
                      value={newResult.value || ''}
                      onChange={(e) => handleNewResultChange('value', e.target.value)}
                      placeholder="Value"
                      className="block w-full p-2 border border-gray-300 rounded-md shadow-sm mr-2"
                    />
                    <input
                      type="text"
                      value={newResult.unit || ''}
                      onChange={(e) => handleNewResultChange('unit', e.target.value)}
                      placeholder="Unit"
                      className="block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                    />
                    <button
                      type="button"
                      onClick={addResult}
                      className="ml-2 bg-blue-500 text-white p-2 rounded-md"
                    >
                      Add Result
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/* {projectType === 'service' && (
              <div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Services</label>
                  <br />
                  <p>Title<span style={{ marginLeft: '255px' }}>Description</span><span style={{ marginLeft: '200px' }}>Icon URL</span><span style={{ marginLeft: '220px' }}>Images Link</span></p>
                  <br />

                  {services.map((service, index) => (
                    <div key={index} className="flex mb-2">
                      <input
                        type="text"
                        value={service.title || ''}
                        onChange={(e) => handleServiceChange(service.id, 'title', e.target.value)}
                        className="block w-full p-2 border border-gray-300 rounded-md shadow-sm mr-2"
                        disabled={!isEditing}
                      />
                      <input
                        type="text"
                        value={service.description || ''}
                        onChange={(e) => handleServiceChange(service.id, 'description', e.target.value)}
                        className="block w-full p-2 border border-gray-300 rounded-md shadow-sm mr-2"
                        disabled={!isEditing}
                      />
                      <input
                        type="text"
                        value={service.icon || ''}
                        onChange={(e) => handleServiceChange(service.id, 'icon', e.target.value)}
                        className="block w-full p-2 border border-gray-300 rounded-md shadow-sm mr-2"
                        disabled={!isEditing}
                      />
                      <input
                        type="text"
                        value={(service.images || []).join(', ')}
                        onChange={(e) => handleServiceChange(service.id, 'images', e.target.value.split(', '))}
                        className="block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                        disabled={!isEditing}
                      />
                    </div>
                  ))}
                </div>

                <div className="mb-4">
                  <h3 className="text-lg font-semibold mb-2">Add Service</h3>
                  <input
                    type="text"
                    value={newService.title || ''}
                    onChange={(e) => handleNewServiceChange('title', e.target.value)}
                    placeholder="Title"
                    className="block w-full p-2 border border-gray-300 rounded-md shadow-sm mb-2"
                    disabled={!isEditing}
                  />

                  <input
                    type="text"
                    value={newService.description || ''}
                    onChange={(e) => handleNewServiceChange('description', e.target.value)}
                    placeholder="Description"
                    className="block w-full p-2 border border-gray-300 rounded-md shadow-sm mb-2"
                    disabled={!isEditing}
                  />

                  <input
                    type="text"
                    value={newService.icon || ''}
                    onChange={(e) => handleNewServiceChange('icon', e.target.value)}
                    placeholder="Icon URL"
                    className="block w-full p-2 border border-gray-300 rounded-md shadow-sm mb-2"
                    disabled={!isEditing}
                  />

                  {newService.images.map((img, index) => (
                    <div key={index} className="flex mb-2">
                      <input
                        type="text"
                        value={img || ''}
                        onChange={(e) => handleNewImageChange(index, e.target.value)}
                        placeholder={`Image URL ${index + 1}`}
                        className="block w-full p-2 border border-gray-300 rounded-md shadow-sm mr-2"
                        disabled={!isEditing}
                      />
                    </div>
                  ))}

                  <button
                    type="button"
                    onClick={addNewImage}
                    className="bg-blue-500 text-white p-2 rounded-md mb-2"
                    disabled={!isEditing}
                  >
                    Add Image
                  </button>

                  <button
                    type="button"
                    onClick={addService}
                    className="bg-blue-500 text-white p-2 rounded-md"
                    disabled={!isEditing}
                  >
                    Add Service
                  </button>
                </div>
              </div>
            )} */}
            {projectType === 'service' && (
  <div>
    <div className="mb-4">
      {services.length > 0 && (
        <>
          <label className="block text-sm font-medium text-gray-700">Services</label>
          <br />
          <p>Title<span style={{ marginLeft: '255px' }}>Description</span><span style={{ marginLeft: '200px' }}>Icon URL</span><span style={{ marginLeft: '220px' }}>Images Link</span></p>
          <br />
        </>
      )}

      {services.map((service, index) => (
        <div key={index} className="flex mb-2">
          <input
            type="text"
            value={service.title || ''}
            onChange={(e) => handleServiceChange(service.id, 'title', e.target.value)}
            className="block w-full p-2 border border-gray-300 rounded-md shadow-sm mr-2"
            disabled={!isEditing}
          />
          <input
            type="text"
            value={service.description || ''}
            onChange={(e) => handleServiceChange(service.id, 'description', e.target.value)}
            className="block w-full p-2 border border-gray-300 rounded-md shadow-sm mr-2"
            disabled={!isEditing}
          />
          <input
            type="text"
            value={service.icon || ''}
            onChange={(e) => handleServiceChange(service.id, 'icon', e.target.value)}
            className="block w-full p-2 border border-gray-300 rounded-md shadow-sm mr-2"
            disabled={!isEditing}
          />
          <input
            type="text"
            value={(service.images || []).join(', ')}
            onChange={(e) => handleServiceChange(service.id, 'images', e.target.value.split(', '))}
            className="block w-full p-2 border border-gray-300 rounded-md shadow-sm"
            disabled={!isEditing}
          />
        </div>
      ))}
    </div>

    <div className="mb-4">
      <h3 className="text-lg font-semibold mb-2">Add Service</h3>
      <input
        type="text"
        value={newService.title || ''}
        onChange={(e) => handleNewServiceChange('title', e.target.value)}
        placeholder="Title"
        className="block w-full p-2 border border-gray-300 rounded-md shadow-sm mb-2"
        disabled={!isEditing}
      />

      <input
        type="text"
        value={newService.description || ''}
        onChange={(e) => handleNewServiceChange('description', e.target.value)}
        placeholder="Description"
        className="block w-full p-2 border border-gray-300 rounded-md shadow-sm mb-2"
        disabled={!isEditing}
      />

      <input
        type="text"
        value={newService.icon || ''}
        onChange={(e) => handleNewServiceChange('icon', e.target.value)}
        placeholder="Icon URL"
        className="block w-full p-2 border border-gray-300 rounded-md shadow-sm mb-2"
        disabled={!isEditing}
      />

      {newService.images.map((img, index) => (
        <div key={index} className="flex mb-2">
          <input
            type="text"
            value={img || ''}
            onChange={(e) => handleNewImageChange(index, e.target.value)}
            placeholder={`Image URL ${index + 1}`}
            className="block w-full p-2 border border-gray-300 rounded-md shadow-sm mr-2"
            disabled={!isEditing}
          />
        </div>
      ))}

      <button
        type="button"
        onClick={addNewImage}
        className="bg-blue-500 text-white p-2 rounded-md mb-2"
        disabled={!isEditing}
      >
        Add Image
      </button>

      <button
        type="button"
        onClick={addService}
        className="bg-blue-500 text-white p-2 rounded-md"
        disabled={!isEditing}
      >
        Add Service
      </button>
    </div>
  </div>
)}


            <div className="mb-4">
              <button
                type="button"
                onClick={handleSave}
                className="bg-green-500 text-white p-2 rounded-md"
                disabled={!isEditing}
              >
                Save
              </button>
              <button
                type="button"
                onClick={handleEdit}
                className="bg-blue-500 text-white p-2 rounded-md ml-2"
                disabled={isEditing}
              >
                Edit
              </button>
              <button
      type="button"
      onClick={() => handleDelete(project.slug)}  // Pass the project slug here
      className="bg-red-500 text-white p-2 rounded-md ml-2"
    >
      Delete
      </button>
            </div>
          </form>
        </div>

        <div className="w-1/4 pl-4">
          <h2 className="text-xl font-bold mb-4">Additional Actions</h2>

          <div className="mb-4">
  <label className="block text-sm font-medium text-gray-700">Type of Project</label>
  <select
    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm js-example-basic-multiple-types"
    name="types[]"
    multiple
    value={types} 
    onChange={handleTypeChange}
  >
    {projectTypes.map(type => (
      <option key={type} value={type}>{type}</option>
    ))}
  </select>
</div>
<div className="mb-4">
  <label className="block text-sm font-medium text-gray-700">Industry</label>
  <select
    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm js-example-basic-multiple-industry"
    name="industry[]"
    multiple
    value={industry} 
    onChange={handleIndustryChange}
  >
    {industries.map(industry => (
      <option key={industry} value={industry}>{industry}</option>
    ))}
  </select>
</div>
<div className="mt-2">
  <label htmlFor="status" className="block text-sm font-medium text-gray-700">
    Status:
  </label>
  <select
    id="status"
    value={project.status} 
    onChange={handleStatusChange}
    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
  >
    <option value="Publish">Publish</option>
    <option value="Draft">Draft</option>
  </select>
</div>

        </div>
      </div>
    </div>
  );
};

export default ProjectDetails;
