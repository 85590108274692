import BaseURL from '../../../../components/Other/BaseURL';
import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import $ from 'jquery';
import DataTable from 'datatables.net';
import 'datatables.net-responsive-dt/css/responsive.dataTables.css';
import 'datatables.net-responsive';
import '../style.css';

function DraftsTabContents() {
  const [AllBlogs, setAllBlogs] = useState([]);

  const DataRefresh = useCallback(async () => {
    await axios.get(BaseURL + '/get-all-blogs/draft', {})
      .then((res) => {
        setAllBlogs(res.data.data);
        console.log(res.data.data);
      })
      .catch((err) => { console.log(err); });
  }, []);

  useEffect(() => {
    DataRefresh();
  }, [DataRefresh]);

  useEffect(() => {
    initializeDataTable();
  }, [AllBlogs]);

  const initializeDataTable = () => {
    if ($.fn.DataTable.isDataTable('#myDataTable')) {
      $('#myDataTable').DataTable().destroy();
    }
    $('#myDataTable').DataTable({
      data: AllBlogs,
      columns: [
        {
          data: 'title',
          render: function (data, type, row) {
            return `<span style=" font-weight: 600;">`+data + `
            <br />
            <div class='action-links' style='display:none;'>
              <a href="#">Edit</a> | <a href="#">Trash</a> | <a href="#">View</a>
            </div>
            `;
          }
        },
        { data: 'author',
          render: function(data,type,row){
            return `<span style=" font-weight: 500;  ">` + data + `</span>`
         }
         },
        {
          data: 'categories',
          render: function (data, type, row) {
            return JSON.parse(data).join(', ');
          }
        },
        {
          data: 'tags',
          render: function (data, type, row) {
            return JSON.parse(data).length > 0 ? JSON.parse(data).join(', ') : '-';
          }
        },
        { data: 'status',
          render: function (data, type, row) {
            if (data === "draft") {
            return `<span style="
            background-color: red;
            color: white;
            padding: 2px 8px;
            border-radius: 3px;
            font-size: 12px;
            text-transform: capitalize;
            display: inline-block;
            ">` + data + `</span>`;}
       },
         },
        { data: 'created_on' },
        { data: 'last_modified' }
      ],
      responsive: true,
      // order: [[5, 'desc']] // Sort by created_on column descending
    });
  };

  return (
    <>
      <div className='tabContentsContainer mt-3 p-4'>
        <table id="myDataTable" className="display fs14 data_table" style={{ width: '100%' }}>
          <thead>
            <tr>
              <th style={{ width: '30%' }}>Blog Title</th>
              <th>Author</th>
              <th>Categories</th>
              <th>Tags</th>
              <th>Status</th>
              <th>Created On</th>
              <th>Last Modified</th>
            </tr>
          </thead>
        </table>
      </div>
    </>
  );
}

export default DraftsTabContents;
