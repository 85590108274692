import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
    useLocation,
  } from "react-router-dom";
  import {
    LayoutDashboard,
    Home,
    StickyNote,
    Settings,
    Bookmark,
  } from "lucide-react";
  import { SidebarItem } from "./components/sidebar/SidebarItem";
  import Sidebar from "./components/sidebar/Sidebar";
  
  // import Blogs from "./app/blog/Blogs";
  import Projects from "./app/projects/Projects";
  import Setting from "./app/settings/Setting";
  import Dashboard from "./app/dashboard/Dashboard";
  import CaseStudies from "./app/case-studies/CaseStudies";
  import Login from "./auth/Login";
  import AddProject from "./app/projects/AddProject";
  import AllProjects from "./app/projects/AllProjects";
  import ProjectDetails from "./app/projects/ProjectDetails";
  // import 'datatables.net-dt/css/jquery.dataTables.min.css'; 
  import 'datatables.net-dt/css/dataTables.dataTables.css';
  import Blogs from './app/blog/Blogs';
  import EditBlog from './app/blogs/EditBlog';
  import AddBlog from './app/blogs/AddBlog';
  import FileUpload from './app/blogs/BlogComponents/FileUpload';
  
  
  function AppContent() {
    const location = useLocation();
    const hideSidebarPaths = ["/login"];
  
    return (
      <div className="flex">
        {!hideSidebarPaths.includes(location.pathname) && (
          <Sidebar>
            <SidebarItem
              icon={<Home size={20} />}
              text="Dashboard"
              id="dashboard"
            />
            <SidebarItem
              icon={<Bookmark size={20} />}
              text="Projects"
              id="projects"
              dropdownItems={[
                { text: "All Projects", path: "/projects/all-projects" },
                { text: "Add Project", path: "/projects/add-project" },
              ]}
            />
            <SidebarItem
              icon={<LayoutDashboard size={20} />}
              text="Case Studies"
              id="case-studies"
            />
            <SidebarItem
              icon={<StickyNote size={20} />}
              text="Blogs"
              id="blogs"
            />
            <hr className="my-3" />
            <SidebarItem
              icon={<Settings size={20} />}
              text="Settings"
              id="settings"
            />
          </Sidebar>
        )}
        <div className="flex-1 p-4">
          <Routes>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/case-studies" element={<CaseStudies />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/settings" element={<Setting />} />
            <Route path="/projects/add-project" element={<AddProject />} />
            <Route path="/projects/all-projects" element={<AllProjects />} />
            <Route path="/projects/:slug" element={<ProjectDetails />} />
            {/* //<Route path='/blogs' element={<Blogs currentpage='blogs' />}/> */}
            <Route path='/edit-blog/:slug' element={<EditBlog currentpage='blogs' />}/>
            <Route path='/file-upload' element={<FileUpload currentpage='blogs' />}/>
            <Route path='/add-blog' element={<AddBlog currentpage='blogs' />}/>
  
          </Routes>
        </div>
      </div>
    );
  }
  
  function App() {
    return (
      <Router>
        <AppContent />
      </Router>
    );
  }
  
  export default App;
  